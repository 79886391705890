import BaseController from '@/service/BaseService'
import MenuRequest from '@/request/System/MenuRequest'

const base = new BaseController()

export default {
  data () {
    return {
      Scene: 0, // 0表示弹框为添加，1表示弹框为修改
      dialogVisible: false,
      showDialog: false,
      // 表格加载
      tableLoad: false,
      // 表单信息
      form: {
        ParentId: 0,
        Name: '',
        Path: '',
        TableName: '',
        Order: 0,
        Icon: 'refresh-right',
        Rule: 1,
        Scene: 1,
        Status: 1
      },
      // 表单验证规则
      rules: {
        Name: [{
          required: true,
          message: '请输入名称'
        }],
        TableName: [{
          required: true,
          message: '请输入表名'
        }],
        Status: [{
          required: true,
          message: '请选择状态'
        }],
        Icon: [{
          required: true,
          message: '请输入图标'
        }]
      },
      // 分页信息[前端控制单页数量]
      pageData: {
        Page: 1,
        Limit: 6000,
        Total: 0,
        List: []
      },

      // 表单筛选
      searchForm: {
        Name: ''
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new MenuRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      this.showDialog = true
      this.Scene = 1
      this.form.Scene = 1
      this.form.ParentId = 0
    },
    // 编辑事件
    editClick: async function (item) {
      console.log(item)
      await base.editClick(item)
    },
    addAuthClick: function (row) {
      this.showDialog = true
      this.Scene = 1
      this.form.Scene = 3
      this.form.ParentId = row.Id
    },
    // 添加子类
    addSonClick: function (row) {
      this.showDialog = true
      this.form.Scene = 2
      this.Scene = 1
      this.form.ParentId = row.Id
    }
  }
}
